@import url("https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Epilogue", sans-serif;
}
/* <---------- var start ----------> */
:root {
  --Ocean_Blue: #007bff;
  --Digital_Gold: #d9b701;
  --Forest_Green: #4caf50;
  --Unity_Grey: #808080;
  --Fern_Frond: #4f7942;
  --Harmony_Teal: #008080;
  --black: #000000;
  --white: #ffffff;
  --header_height: 100px;
  --section_space: 80px;
  /* scroll-behavior: inherit; */
}
/* <---------- var end ----------> */
a {
  text-decoration: none;
}
p {
  margin-bottom: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}
h2 {
  color: var(--black);
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  text-transform: capitalize;
}
h3 {
  color: var(--black);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
h5 {
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
}
h6 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--white);
}
.two-space {
  padding: var(--section_space) 0;
}
.top-space {
  padding-top: var(--section_space);
}
.bot-space {
  padding-bottom: var(--section_space);
}
.m-w {
  font-weight: 500;
}
.s-w {
  font-weight: 600;
}
.Ocean_Blue_bg {
  background-color: #007bff !important;
}
.Digital_Gold_bg {
  background-color: #d9b701 !important;
}
.Forest_Green_bg {
  background-color: #4caf50 !important;
}
.Unity_Grey_bg {
  background-color: #808080 !important;
}
.Fern_Frond_bg {
  background-color: #4f7942 !important;
}
.Harmony_Teal_bg {
  background-color: #008080 !important;
}

.Ocean_Blue_text {
  color: #007bff !important;
}
.Digital_Gold_text {
  color: #d9b701 !important;
}
.Forest_Green_text {
  color: #4caf50 !important;
}
.Unity_Grey_text {
  color: #808080 !important;
}
.Fern_Frond_text {
  color: #4f7942 !important;
}
.Harmony_Teal_text {
  color: #008080 !important;
}
svg path {
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}
.fill-btn {
  height: 50px;
  background-color: var(--black);
  color: var(--white);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  border: 1px solid transparent;
  padding: 15px 20px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fill-btn:hover {
  background-color: var(--Forest_Green) !important;
  color: var(--white) !important;
  border-color: transparent !important;
}
.fill-btn:hover svg path {
  fill: var(--white);
}
.border-btn {
  background-color: transparent;
  color: var(--black);
  border-color: var(--black);
}
/* .border-btn:hover{
    background-color: transparent;
    color: var(--black);
    border-color: var(--black);
} */

/* <------------- number arrow start ------------> */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
/* <------------- number arrow end ------------> */

.number-text {
  font-family: "Poppins", sans-serif !important;
}
.main-wapper {
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

/* <---------- header start ----------> */
#header {
  height: var(--header_height);
}
.header-block {
  background-color: var(--Ocean_Blue);
}
.loading-screen {
  background-color: var(--Ocean_Blue);
}
.loading-screen span {
  color: #fff;
}
.logo-bar img {
  max-width: 300px;
  object-fit: contain;
}
.header-caption .fill-btn {
  font-weight: 600;
}
/* <---------- header end ----------> */

/* <---------- footer start ----------> */
.footer-block .section-bg {
  padding: 40px 30px;
}
/* .listing-link li .nav-link{
    color: rgba(255, 255, 255, 0.55);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.listing-link li .nav-link:hover{
    color: #fff;
}
.footer-caption .link-block{
    column-gap: 70px;
} */

.footer-bot-caption p,
.footer-bot-caption .link-text {
  color: rgba(255, 255, 255, 0.55);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}
.social-media-bar .media-item {
  width: 32px;
  height: 32px;
}
.social-media-bar .media-item svg {
  width: 16px;
}
/* <---------- footer end ----------> */

.about-leos-section h2 {
  color: var(--Forest_Green);
}
.text-caption span {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--Ocean_Blue);
}

.buy-leos-form-block .input-group {
  height: 48px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.29);
}
.buy-leos-form-block .input-group .input-group-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.buy-leos-form-block .form-control {
  font-family: "Poppins", sans-serif !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: var(--white);
}
.form-control:focus {
  box-shadow: none;
}
.convert-leos-capton .text-caption .convert-leos-text,
.convert-leos-capton .text-caption .convert-leos-text span {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.features-section .section-bg {
  background-color: #f5faf5;
}
.features-listing-box .item-box {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding: 25px 30px;
}
.link-arrow-text p {
  font-size: 14px;
}
.chatgpt-box {
  width: 100%;
  background-image: url(../img/gpt-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding: 30px 0;
}
.chatgpt-box p {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.heading-box span {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

/* <---------- token sale start ----------> */
.token-sale-box {
  padding: 50px;
  border-radius: 15px;
  border: 2px solid var(--Forest_Green);
}
.token-sale-box .token-unlock-caption {
  border-radius: 10px;
  padding: 25px;
}
.token-sale-box .token-unlock-caption .text-caption span {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
}
.token-sale-box .token-unlock-caption .text-caption span p {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.tokensale-info-box {
  border-radius: 10px;
  background: #f9f9f9;
  padding: 20px;
  height: 100%;
}
.tokensale-info-box .title {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tokensale-info-box span {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
/* <---------- token sale end ----------> */

/* <---------- token-information-box start ----------> */
.token-information-box {
  padding: 70px 50px 0px 50px;
  background: linear-gradient(
    180deg,
    #e6f9ff -1.67%,
    rgba(247, 247, 247, 0) 100%
  );
  border-radius: 40px 40px 0px 0px;
}
.token-information-box .information-listing li p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  color: #5c5c5c;
}
.token-information-box .information-listing li span {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  color: var(--black);
}
.shadow-box-wapper {
  max-width: 830px;
}
.shadow-box {
  padding: 25px 30px;
  border-radius: 15px;
  background-color: var(--white);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09);
}
.shadow-box .title {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--black);
}
/* <---------- token-information-box end ----------> */

/* <---------- table start ----------> */
.table {
  table-layout: fixed;
}
.table-wapper {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.table thead tr th {
  background-color: var(--Harmony_Teal);
  color: var(--white);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  border: 1px solid #edf1fd;
  padding: 20px 15px;
  vertical-align: middle;
}
.table thead tr th:first-child {
  border-radius: 10px 0 0 0;
  -webkit-border-radius: 10px 0 0 0;
  -moz-border-radius: 10px 0 0 0;
  -ms-border-radius: 10px 0 0 0;
  -o-border-radius: 10px 0 0 0;
}
.table thead tr th:last-child {
  border-radius: 0 10px 0 0;
  -webkit-border-radius: 0 10px 0 0;
  -moz-border-radius: 0 10px 0 0;
  -ms-border-radius: 0 10px 0 0;
  -o-border-radius: 0 10px 0 0;
}
.table tbody tr td {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: 1px solid #edf1fd;
  padding: 20px 15px;
  vertical-align: middle;
}
.table tbody tr td:first-child {
  text-align: start;
  font-size: 15px;
  font-weight: 600;
}
/* <---------- table end ----------> */

/* <---------- select flag box start ----------> */
.flag-dropdown {
  padding: 0 !important;
}
.selected-flag-btn {
  border: none !important;
  /*height: 24px;
    */
  background-color: #00264f !important;
  border-radius: 100px !important;
  -webkit-border-radius: 100px !important;
  -moz-border-radius: 100px !important;
  -ms-border-radius: 100px !important;
  -o-border-radius: 100px !important;
  color: #fff !important;
  font-size: 12px !important;
  height: 32px !important;
  padding: 5px 5px !important;
}
.selected-flag-btn svg path {
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
}
.selected-flag-btn .ReactFlagsSelect-module_selectValue__152eS {
  margin: 0px !important;
  padding-right: 0px !important;
  font-family: "Poppins", sans-serif !important;
}
.selected-flag-btn .ReactFlagsSelect-module_label__27pw9 {
  padding-left: 5px !important;
  font-family: "Poppins", sans-serif !important;
}
.selected-flag-btn:after {
  border-top-color: var(--white) !important;
}
.selected-flag-btn[aria-expanded="true"]:after {
  border-bottom-color: var(--white) !important;
}
.flag-dropdown .ReactFlagsSelect-module_fullWidthOptions__1XeR6 {
  min-width: 120px;
  left: inherit;
}
.flag-dropdown .ReactFlagsSelect-module_label__27pw9 {
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  padding-left: 5px;
}

.currency_select {
  color: #fff;
  background: #00264f;
  border: none;
  border-radius: 20px;
  font-size: 15px;
  padding: 6px 10px;
  outline: none;
}
.currency_select:focus {
  outline: none;
}
.currency_select option {
  color: black;
  background-color: #fff;
}

/* <---------- select flag box end ----------> */

/* <---------- stepform wapper starts ----------> */
.stepform-wapper .stepform-wapper .container,
.stepform-wapper .stepform-block,
.content-wapper {
  width: 100%;
  min-height: calc(100vh - var(--header_height));
}
.stepform-progress .StepperContainer-0-2-1 {
  background-color: #f9f9f9;
  padding: 25px 40px;
  border-radius: 0px 0px 20px 20px;
}
.stepform-progress #RFS-Connector {
  border-style: dashed;
  border-width: 1px;
}
.stepform-progress #RFS-StepButton span {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  font-family: "Poppins", sans-serif !important;
}
.stepform-progress #RFS-Label {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.step-banner-imgbar img {
  width: 100%;
  object-fit: contain;
}
.step-banner-block .caption-box {
  gap: 33px;
}
.step-banner-block .caption-box h3 {
  font-weight: 300;
  text-transform: inherit;
}
.step-banner-block .caption-box p {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: #363636;
}
.badge-progress{
  font-size: 16px;
  font-weight: 500;
}
.listing-item-box {
  list-style-position: inside;
}
.step-banner-block .listing-item-box li {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: #363636;
}
.nationality-step-block .listing-item-box,
.nationality-step-block .step-radio-btn-bar,
.login-step-block .subscribe-marketing-checkbox {
  display: block !important;
}
.nationality-step-block .step-link-btn-bar {
  display: none !important;
}
.label-radio-btn .form-check-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
}
.label-radio-btn .form-check-label {
  border-radius: 8px;
  background-color: transparent;
  padding: 12px 20px;
  border: 1px solid transparent;
  cursor: pointer;
}
.label-radio-btn .form-check-label span {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.label-radio-btn .form-check-label.grey-radio-btn {
  border-color: var(--Unity_Grey);
}
.label-radio-btn .form-check-label.blue-radio-btn {
  border-color: var(--Ocean_Blue);
}
.label-radio-btn .form-check .form-check-input:checked + .form-check-label {
  border-color: transparent;
  color: var(--white);
}
.label-radio-btn
  .form-check
  .form-check-input:checked
  + .form-check-label.grey-radio-btn {
  background-color: var(--Unity_Grey);
}
.label-radio-btn
  .form-check
  .form-check-input:checked
  + .form-check-label.grey-radio-btn
  svg
  path {
  fill: var(--white) !important;
}
.label-radio-btn
  .form-check
  .form-check-input:checked
  + .form-check-label.blue-radio-btn {
  background-color: var(--Ocean_Blue);
}
.label-radio-btn
  .form-check
  .form-check-input:checked
  + .form-check-label.blue-radio-btn
  svg
  path {
  fill: var(--white) !important;
}
.payment-step-box {
  width: 100%;
  max-width: 810px;
  margin: 0 auto;
  padding: 34px 24px;
  gap: 40px;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  background-color: var(--white);
}
.amount-listing li {
  padding: 10px 0;
  border-bottom: 1px dashed #e0e0e0;
}
.amount-listing li .label {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.amount-listing li .price {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.payment-option-bar .heading {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--black);
}
.payment-option-btn .form-check-label {
  border-color: transparent !important;
  border-width: 2px !important;
  background: #f8f8f8;
  padding: 20px 30px;
}
.payment-option-btn .form-check .form-check-input:checked + .form-check-label {
  border-radius: 10px;
  border-color: var(--Ocean_Blue) !important;
  background-color: rgba(0, 123, 255, 0.04) !important;
  color: var(--Ocean_Blue) !important;
}

.payment-option-btn
  .form-check
  .form-check-input:checked
  + .form-check-label
  .checked-icon {
  opacity: 1;
  visibility: visible;
}
.payment-option-btn
  .form-check
  .form-check-input:checked
  + .form-check-label
  .checked-icon
  svg
  path {
  fill: var(--white) !important;
}
.payment-option-btn .form-check-label span {
  font-size: 18px;
}
.payment-option-info {
  border-radius: 10px;
  border: 1px solid #ffed8f;
  padding: 20px;
  background: rgba(255, 248, 221, 0.22);
}
.payment-option-info .info-payemnt-listing {
  list-style-position: inside;
}
.payment-option-info .info-payemnt-listing li {
  font-size: 14px;
  color: var(--black);
}
.form-check-label .checked-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.form-control-custom {
  font-size: 14px;
  min-height: 50px;
  padding-left: 1rem;
  background: transparent;
}
.form-control-custom::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #a6a6a6;
}
.form-control-custom:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #a6a6a6;
  opacity: 1;
}
.form-control-custom::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #a6a6a6;
  opacity: 1;
}
.form-control-custom:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a6a6a6;
}
.form-control-custom::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a6a6a6;
}

.form-control-custom::placeholder {
  /* Most modern browsers support this now. */
  color: #a6a6a6;
}

.checked-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: var(--Ocean_Blue);
  opacity: 0;
  visibility: hidden;
}
.square-tick-check .form-check-input {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  cursor: pointer;
  background-color: #f9f9fb;
}
.subscribe-marketing-checkbox .form-check {
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 15px !important;
}
.square-tick-check .form-check-input:checked[type="checkbox"] {
  background-color: var(--Ocean_Blue);
  border-color: transparent;
}
.square-tick-check .form-check-label {
  cursor: pointer;
  font-size: 14px;
}
.square-tick-check .form-check-input:focus {
  box-shadow: none;
  outline: none;
}

.veriff-description {
  display: none !important;
}
#veriff-root {
  flex-grow: 1 !important;
}
.veriff-container .veriff-submit {
  height: 50px;
  background-color: var(--black);
  color: var(--white);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  border: 1px solid transparent;
  padding: 15px 20px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.veriff-container .veriff-submit:hover {
  background-color: var(--Forest_Green) !important;
  color: var(--white) !important;
  border-color: transparent !important;
}

/* step1 start */

.step1-title {
  font-weight: 300;
  text-transform: inherit;
  font-size: 32px;
  line-height: normal;
  margin-bottom: 30px;
}

.square-tick-check2 .form-check-input {
  width: 25px;
  height: 25px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  cursor: pointer;
  background-color: #f9f9fb;
  display: none;
}

.square-tick-check2 .form-check-input:checked[type="checkbox"] {
  background-color: var(--Ocean_Blue);
  border-color: transparent;
}
.square-tick-check2 .form-check-label {
  cursor: pointer;
  font-size: 14px;
}
.square-tick-check2 .form-check-input:focus {
  box-shadow: none;
  outline: none;
}
.metamask-other-wallet{
  max-width: 542px;
  font-size: 18px;
  line-height: 18.45px;
}

.preparation-box {
  background: #f6f6f6;
  border-radius: 15px;
  border: 1px solid #dedede;
  padding-top: 15px;
}
.preparation-title {
  background-color: #d9b701;
  color: #fff;
  font-size: 16px;
  padding: 15px;
  padding-right: 20px;
  line-height: 1;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  display: inline-block;
}

.preparation-list {
  padding: 30px;
}
.preparation-list ul {
  /* list-style: none; */
  padding-left: 0;
}
.preparation-list ul li {
  padding: 15px;
  border-bottom: 1px solid #dedede;
  font-size: 20px;
}
.preparation-list ul li label {
  font-size: 20px !important;
}
.preparation-list ul li:last-child {
  border: none;
}

.step-error {
  color: #ff0000;
  background: #ffefef;
  padding: 15px;
  line-height: 1;
  display: inline-block;
  border-radius: 10px;
}

.download-btn-bar{
  max-width: 120px;
  max-height: 34px;
} 
.country_modal {
  padding: 0;
  appearance: none;
  border: none;
  background: transparent;
  color:#007BFF;
  font-weight: 600;
}

.meta-ex-btn {
  background-color: #fff;
  border: 1px solid #5c5c5c;
  padding: 5px 10px;
  border-radius: 5px;
}
.need-help-bar ul li:first-child {
  padding-left: 0;
}
.need-help-bar ul li:last-child {
  padding-right: 0;
}
.need-help-bar ul li {
  padding: 0 15px;
}
.need-help-bar ul li:not(:last-child) {
  border-right: 1px solid #8c8c8c;
}
.need-help-bar ul li a {
  font-size: 16px;
  color: #000000;
  text-decoration: underline;
  font-weight: 400;
}
.modal_header {
  margin-bottom: 20px;
}

.modal_header h3 {
  font-weight: 600;
}

.close_btn {
  appearance: none;
  background: none;
  padding: 0;
  border: none;
  font-size: 30px;
}

.next-btn {
  padding: 10px 35px;
  font-size: 18px;
  background: #4caf50;
  color: #fff;
  border: 1px solid #4caf50;
  border-radius: 25px;
}
.prev-btn {
  padding: 10px 35px;
  font-size: 18px;
  background: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 25px;
  margin-right: 15px;
}
.next-btn:disabled {
  opacity: 0.65;
  background: #d9d9d9;
  border-color: #d9d9d9;
}

.alert {
  padding: 10px;
}
.alert-danger {
  background-color: #ffefef;
  color: #ff0000;
  border: none;
}

.success-wrap {
  background: #4caf5014;
  padding: 25px;
  border-radius: 15px;
}
.success-wrap .icon {
  font-size: 25px;
}
.success-wrap .title {
  color: #fff;
  background: #4caf50;
  padding: 7px 25px;
  margin-left: -25px;
  font-size: 18px;
  font-weight: 500;
}
.success-wrap .user-info {
  background-color: #fff;
  padding: 10px;
  font-size: 16px;
  margin-top: 20px;
}

.btn-wrap {
  border: 1px solid #4caf50;
  border-radius: 25px;
}

.btn-wrap .prev {
  padding: 10px 35px;
  font-size: 18px;
  border-radius: 25px;
  border: none;
  background: transparent;
  color: #4caf50;
  width: 164px;
}
.btn-wrap .next {
  padding: 10px 35px;
  font-size: 18px;
  background: #4caf50;
  color: #fff;
  border-radius: 25px;
  border: none;
  width: 164px;
}

.gray-text {
  color: #a6a6a6;
}
.pad-15 {
  padding: 15px;
}

.left-red {
  border-bottom-left-radius: 15px;
}

/* step1 end */
/* <---------- stepform wapper end ----------> */

/* <---------- paymentsuccessfull wapper start ----------> */
.paymentsuccessfull-wapper .caption-box {
  border-radius: 20px;
  border: 1px solid rgba(76, 175, 80, 0.2);
  background: var(--white);
}
.paymentsuccessfull-wapper .caption-box .label-box {
  background: rgba(76, 175, 80, 0.09);
  padding: 30px 15px;
}
.paymentsuccessfull-wapper .caption-box .body-box {
  padding: 30px 50px;
}
.paymentsuccessfull-wapper .caption-box .body-box .amount-listing li .price {
  font-size: 16px;
}
.paymentsuccessfull-wapper .caption-box .body-box .text-caption p {
  font-size: 18px;
}
.paymentsuccessfull-wapper .caption-box .body-box .text-caption span {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
  color: var(--black);
}
.modal .modal-body {
  padding: 40px 50px;
}
/* <---------- paymentsuccessfull wapper end ----------> */

/* <---------- paymentfailed wapper start ----------> */
.paymentfailed-wapper .caption-box {
  border-radius: 20px;
  border: 1px solid rgba(255, 38, 0, 0.2);
  background: var(--white);
  overflow: hidden;
}
.paymentfailed-wapper .caption-box .label-box {
  background: rgba(255, 38, 0, 0.2);
  padding: 30px 15px;
}
.paymentfailed-wapper .caption-box .body-box {
  padding: 30px 50px;
}
.paymentfailed-wapper .caption-box .body-box .amount-listing li .price {
  font-size: 16px;
}
.paymentfailed-wapper .caption-box .body-box .text-caption p {
  font-size: 18px;
}
.paymentfailed-wapper .caption-box .body-box .text-caption span {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
  color: var(--black);
}
.modal .modal-body {
  padding: 40px 50px;
}
/* <---------- paymentsuccessfull wapper end ----------> */

/* <---------- var end ----------> */
a {
  text-decoration: none;
}
p {
  margin-bottom: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

h3 {
  color: var(--black);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.two-space {
  padding: var(--section_space) 0;
}
.top-space {
  padding-top: var(--section_space);
}
.bot-space {
  padding-bottom: var(--section_space);
}
.m-w {
  font-weight: 500;
}
.s-w {
  font-weight: 600;
}
.Ocean_Blue_bg {
  background-color: #007bff !important;
}
.Digital_Gold_bg {
  background-color: #d9b701 !important;
}
.Forest_Green_bg {
  background-color: #4caf50 !important;
}
.Unity_Grey_bg {
  background-color: #808080 !important;
}
.Fern_Frond_bg {
  background-color: #4f7942 !important;
}
.Harmony_Teal_bg {
  background-color: #008080 !important;
}

.Ocean_Blue_text {
  color: #007bff !important;
}
.Digital_Gold_text {
  color: #d9b701 !important;
}
.Forest_Green_text {
  color: #4caf50 !important;
}
.Unity_Grey_text {
  color: #808080 !important;
}
.Fern_Frond_text {
  color: #4f7942 !important;
}
.Harmony_Teal_text {
  color: #008080 !important;
}
svg path {
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}
.text-link-btn {
  text-decoration: underline;
  font-size: 14px;
}
.fill-btn {
  height: 50px;
  background-color: var(--black);
  color: var(--white);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  border: 1px solid transparent;
  padding: 15px 20px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fill-btn:hover {
  background-color: var(--Forest_Green) !important;
  color: var(--white) !important;
  border-color: transparent !important;
}
.fill-btn:hover svg path {
  fill: var(--white);
}
.border-btn {
  background-color: transparent;
  color: var(--black);
  border-color: var(--black);
}
.small-btn {
  font-size: 14px;
  height: 40px;
  padding: 10px 12px;
}
/* <------------- number arrow start ------------> */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
/* <------------- number arrow end ------------> */

.number-text {
  font-family: "Poppins", sans-serif !important;
}
.main-wapper {
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.main-wapper-cpation {
  height: 100vh;
}
.content-wapper .content-detail-block {
  padding: 35px 25px;
  overflow-x: hidden;
}

/* <----------- login form start -----------> */
.form-full-wapper {
  height: 100vh;
}
.form-content {
  border-radius: 20px 20px 0 0;
  -webkit-border-radius: 20px 20px 0 0;
  -moz-border-radius: 20px 20px 0 0;
  -ms-border-radius: 20px 20px 0 0;
  -o-border-radius: 20px 20px 0 0;
  background-color: var(--white);
  /* background: linear-gradient(to right bottom,rgba(255, 255, 255, 50%), rgba(255, 255, 255, 10%)    ); */
  /* backdrop-filter: blur(); */
}
.form-content .title {
  font-size: 28px;
  font-weight: 500;
  width: max-content;
  margin: 0 auto;
  padding: 8px 20px;
  border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  -ms-border-radius: 0 0 10px 10px;
  -o-border-radius: 0 0 10px 10px;
  color: var(--black);
}
.form-full-wapper-caption .logo-bar img {
  max-width: 300px;
  object-fit: contain;
}
.form-content .input-box label {
  font-size: 14px;
  color: var(--black);
}
.form-content .input-box .form-control {
  background: #f8f8f8;
  border: none;
  height: 45px;
  font-size: 14px;
  font-weight: 600;
}
.form-control:focus {
  box-shadow: none;
}
/* <----------- login form end -----------> */

/* <----------- sidebar section start -----------> */
.sidebar-block {
  width: 240px;
  z-index: 1;
}
.sidebar-caption {
  background-color: var(--Ocean_Blue);
}
.sidebar-caption .sidebar-top-box {
  padding: 15px 15px 0 15px;
}
.sidebar-caption .logo-bar img {
  max-width: 200px;
  object-fit: contain;
}
.menu-bar-box .menu-link {
  font-size: 14px;
  color: var(--white);
  padding: 12px 15px;
  display: flex;
  text-transform: capitalize;
  border-bottom: 1px solid rgba(255, 255, 255, 30%);
  font-weight: 400;
}
.menu-bar-box .menu-link.active {
  background-color: var(--white);
  color: var(--Ocean_Blue);
  font-weight: 500;
}
.sidebar-close-btn {
  display: none;
}
/* <----------- sidebar section end -----------> */

/* <----------- topbar section start -----------> */
.top-bar-caption {
  padding: 12px 25px;
  background-color: #f0f7ff;
}
.admin-info-bar .dropdown .btn {
  font-size: 14px;
  font-weight: 500;
}
.dropdown .btn:focus {
  outline: none;
}
.top-bar-caption .dropdown .dropdown-menu {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.top-bar-caption .dropdown .dropdown-menu .dropdown-item {
  font-size: 14px;
}
.top-bar-caption .sidebar-menu-btn {
  display: none;
}
/* <----------- topbar section end -----------> */

/* <----------- datatable section start -----------> */
.datatable-table-block .btn-bar .action-btn {
  width: 35px;
  height: 35px;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.datatable-table-block .btn-bar .action-btn svg {
  width: 12px;
}
.datatable-table-block .lnOUep {
}
/* <----------- datatable section end -----------> */

.count-box {
  background-color: #f9f9f9;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  cursor: pointer;
  border: 1px solid #f9f9f9;
}
.count-box:hover {
  border: 1px solid #007bff;
}
.count-box .label {
  font-size: 16px;
  font-weight: 500;
  color: var(--black);
  text-transform: capitalize;
}
.count-box .statstic {
  font-size: 24px;
  font-weight: 600;
  color: var(--Forest_Green);
}
.login-action-box .login-admin-info {
  border-bottom: 1px dashed #e1e1e1;
}
.login-action-box .login-admin-info .name {
  font-size: 20px;
  font-weight: 600;
  color: var(--Ocean_Blue);
}
.login-action-box .login-admin-info span {
  font-size: 14px;
  font-weight: 400;
}
.short-form-block {
  background: #f9f9f9 !important;
}
.form-block .input-box label {
  font-size: 14px;
  color: var(--black);
}
.form-block .input-box .form-control {
  height: 45px;
  font-size: 14px;
  font-weight: 500;
}
.form-control:disabled {
  background-color: var(--white);
  cursor: no-drop;
}

.loader-container {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
}
.loader-container span.text {
  font-weight: bold;
  text-align: center;
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid var(--Ocean_Blue);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.crypto-connected-account .connected-account > p {
  word-break: break-all;
}
.crypto-connected-account .connected-account > p,
.crypto-connected-account .connected-account > span {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.crypto-connected-account .connected-account > li {
  padding: 10px 0;
}

.crypto-connected-account {
  padding: 13px 27px;
  border: 1px solid #007bff;
  border-radius: 8px;
  background-color: #007bff24;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.circle-animation {
  overflow: hidden;
  position: relative;
}

.circle-animation:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 4px solid transparent;
  border-top-color: #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.metamask-btn {
  background-color: #007bff;
  color: white;

  height: 50px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  border: 1px solid transparent;
  padding: 15px 20px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.metamask-btn:hover {
  opacity: 0.8 !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* redesign payment success */

.payment-success-wapper .caption-box {
  border-radius: 20px;
  border: 1px solid #d7d7d7;
  background: var(--white);
  padding: 40px;
  width: 600px;
  margin: 0 auto;
}

.payment-success-wapper .caption-box .label-box {
  background: linear-gradient(
    90deg,
    rgba(76, 175, 80, 0.15) 0%,
    rgba(255, 255, 255, 0.23) 100%
  );
  padding: 20px;
}

.payment-success-wapper .caption-box .body-box .amount-listing {
  border-bottom: 1px dashed #e0e0e0;
  padding: 10px 0;
}

.payment-success-wapper .caption-box .body-box .amount-listing .label {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  font-family: "Epilogue", sans-serif;
  color: #000;
}

.payment-success-wapper
  .caption-box
  .body-box
  .amount-listing
  .price
  .dollar-amount {
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins";
}

.payment-success-wapper .caption-box .body-box .leo-amount {
  font-size: 20px;
  font-weight: 700;
  line-height: 35px;
  color: #4caf50;
}

.payment-success-wapper
  .caption-box
  .body-box
  .amount-listing
  .price
  .eth-amount {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  color: #000;
}

.payment-success-wapper .caption-box .body-box .btn-bar .border-btn {
  height: 50px;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--ocean-blue-primary, #007bff);
  color: #007bff;
  font-family: Epilogue;
  font-size: 16px;
  font-weight: 500;
}

.payment-success-wapper .caption-box .body-box .text-caption p {
  font-size: 18px;
}

.payment-success-wapper .caption-box .share-purchase {
  padding: 15px 20px;
  background-color: #d9b701;
  border-radius: 8px;
}

.payment-success-banner .right-content .top-content .heading span {
  font-weight: 800;
}

.payment-success-banner .right-content .join-us-wrapper .social-item,
.payment-success-banner .right-content .share-story-wrapper .social-item,
.payment-success-wapper .caption-box .share-purchase .social-item {
  background-color: #fff;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 5px;
  justify-content: center;
}

.payment-success-banner .right-content .join-us-wrapper .social-item,
.payment-success-banner .right-content .share-story-wrapper .social-item {
  width: 115px;
}

.payment-success-wapper .caption-box .share-purchase .social-item {
  padding: 20px 30px;
}

.payment-success-banner {
  background-color: #d3fcff;
  padding: 100px 264px;
}

.payment-success-banner .right-content .join-us-wrapper,
.payment-success-banner .right-content .share-story-wrapper {
  text-align: center;
  padding: 20px;
  border-radius: 8px;
}

.payment-success-banner .right-content .share-story-wrapper {
  background-color: #008080;
  color: #fff;
}

.payment-success-banner .right-content .join-us-wrapper p {
  font-weight: 500 !important;
}

.payment-success-banner .right-content .join-us-wrapper {
  background-color: #fff;
}

.payment-success-banner .right-content .share-story-wrapper .social-item {
  border: 1px solid #008080;
}

.payment-success-banner .right-content .join-us-wrapper .social-item {
  border: 1px solid #ededed;
}

.payment-success-banner .right-content .description p {
  color: #000;
  font-family: "Epilogue";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.success-card-container {
  padding: 100px 0 55px 0px;
}
.veriff-error {
  white-space: pre-line;
}
.btn-success-fill {
  background: #edf2f9;
  border-color: #edf2f9;
  color: #000;
}
.btn-success-fill:hover {
  background: #edf2f9 !important;
  color: #000 !important;
}
.btn-success-fill:hover svg path {
  fill: #4caf50;
}
.btn-success-fill span {
  text-transform: none;
}
.modal.show {
  background: rgba(0, 0, 0, 0.6);
}
.modal .modal-body {
  padding: 0 35px 35px;
  max-height: 790px;
  overflow: auto;
  margin-right: 3px;
}
.modal .modal-body::-webkit-scrollbar {
  width: 8px;
}

.modal .modal-body::-webkit-scrollbar-thumb {
  background: #d6d6d6;
  border-radius: 10px;
}

.modal .modal-body::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.modal .modal-header {
  display: block;
  padding: 35px;
  border-bottom: 0;
}
.modal .modal-header .model-title {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.modal.show .modal-dialog {
  max-width: 570px;
}
.region-title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.country_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.country_list li {
  line-height: 28px;
}
.modal .modal-body {
  padding: 0 35px 35px;
}
.modal .modal-body.pad-top {
  padding: 35px 35px;
}
.modal .modal-close {
  background: none;
  border: none;
}
.modal .modal-content {
  border-radius: 15px;
  border: none;
}
.text-need-help {
  text-decoration: underline;
  font-size: 16px;
  color: #000;
}
.support-users .user h5 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.support-users .user a {
  color: var(--ocean-blue-primary, #007bff);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
}
.support-links a {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.btn-profile {
  background: none;
  border: none;
}
.btn-profile::after {
  display: none;
}
.btn-profile:hover {
  background: none !important;
}
.btn-profile span {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
}
.btn-profile-avatar {
  border-radius: 50%;
  padding: 0;
  width: 48px;
  height: 48px;
}

.btn-profile:hover svg path {
  fill: #c0c0c0 !important;
}
.btn-dark {
  border-radius: 8px;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.btn-outline-dark.modal-cancel {
  border-radius: 8px;
  background: transparent !important;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.logout-close {
  position: absolute;
  top: 20px;
  right: 20px;
}
.badge-success {
  background-color: rgba(129, 199, 132, 0.42);
  border: 1px solid #388e3c;
  border-radius: 30px;
  padding-bottom: 2px;
}
.badge-purple {
  background-color: rgb(70, 21, 178, 0.09);
  border: 1px solid #4615b2;
  border-radius: 30px;
  padding-bottom: 2px;
}

/* HTML: <div class="loader"></div> */
.waiting-loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 5px solid rgba(217, 183, 1, 0.5);
  border-right-color: #d9b701;
  animation: l2 1s infinite linear;
}
@keyframes l2 {
  to {
    transform: rotate(1turn);
  }
}
.download-app-step{
  font-size: 18px; 
}
.finalize-leos{
  font-size: 18px;
  line-height: 18.45px;
  font-weight: 700;
}
.finalize-leos-confirmation{
  font-size: 14px;
  font-weight: 400;
  line-height: 14.35px;
}
.create-walletapp{
  font-size: 18px;
  line-height: 18.45px;
  font-weight: 400;
}
.download-alert{
  background-color: #D9B7011A;
  max-width: 684px;
  color: #000000;
}
.help-discord-link{
  color: #4CAF50
}
.instruction-video{
  height: 371px;
  width: 520px;
  border-radius: 8px;
}
.instruction-video2{
  border-radius: 8px;
}

@media (max-width: 768px){
  .instruction-video{
    display: none;

  }
  .instruction-video2{
    display: block;
  }
}
@media (min-width:769px){
  .instruction-video2{
    display: none;
  }
  .instruction-video{
    display: block;
  }
}
.modal-incomplete-title{
  font-weight: 600;
  line-height: 20.05px;
}
.modal-incomplete-subtitle .btn-leave{
  font-weight: 500;
  line-height: 14.04px;
  color: #000000;
}

.modal-incomplete-header{
  padding: 25px 25px;
}