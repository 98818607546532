@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px;
  }
}

@media (max-width: 1024px) {
  .payment-success-banner {
    padding: 100px 40px;
  }
}

@media (max-width: 992px) {
  :root {
    --section_space: 50px;
  }
  h2 {
    font-size: 34px;
  }
  h3 {
    font-size: 26px;
  }
  p {
    font-size: 14px;
    line-height: 23px;
  }
  .token-information-box .information-listing li span,
  .token-information-box .information-listing li p {
    font-size: 16px;
  }
  .shadow-box .title {
    font-size: 18px;
  }
  .heading-box span {
    font-size: 16px;
  }
  .tokensale-info-box span {
    font-size: 16px;
  }
  .tokensale-info-box .title {
    font-size: 14px;
  }
  .token-sale-box .token-unlock-caption .text-caption span {
    font-size: 18px;
  }
  .token-sale-box .token-unlock-caption {
    padding: 15px;
  }
  .features-listing-box .item-box .img-bar img {
    max-width: 150px;
  }
  .step-banner-block h2 {
    font-size: 26px;
    line-height: 40px;
  }
  .stepform-wapper .stepform-wapper .container,
  .stepform-wapper .stepform-block,
  .content-wapper {
    min-height: 100%;
  }
  .payment-success-banner {
    padding: 50px;
  }

  .payment-success-banner .right-content {
    margin-top: 30px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 24px;
  }
  .shadow-box {
    padding: 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .table thead tr th {
    font-size: 16px;
    padding: 15px;
  }
  .table tbody tr td {
    font-size: 14px;
  }
  .table tbody tr td:first-child {
    font-size: 14px;
  }
  .table {
    table-layout: inherit;
  }
  .payment-step-box {
    padding: 15px;
  }
  .amount-listing li .label {
    font-size: 14px;
  }
  .amount-listing li .price {
    font-size: 14px;
  }
  .payment-option-bar .heading {
    font-size: 16px;
  }
  .payment-option-btn .form-check-label {
    padding: 15px;
  }
  .stepform-progress #RFS-Label {
    font-size: 12px;
  }
  .stepform-progress #RFS-Label {
    margin-top: 10px;
  }
  .stepform-progress .StepperContainer-0-2-1 {
    padding: 10px 0;
    border-radius: 0px 0px 10px 10px;
    -webkit-border-radius: 0px 0px 10px 10px;
    -moz-border-radius: 0px 0px 10px 10px;
    -ms-border-radius: 0px 0px 10px 10px;
    -o-border-radius: 0px 0px 10px 10px;
    overflow-y: auto;
  }
  .stepform-progress #RFS-ConnectorContainer {
    top: 50%;
  }
  .stepform-progress #RFS-StepButton {
    width: 20px;
    height: 20px;
  }
  .stepform-progress #RFS-StepButton span {
    font-size: 10px;
  }
  .modal .modal-header {
    padding: 30px;
  }
  .modal .modal-body {
    padding: 0 30px 30px;
    max-height: 350px;
  }
  .modal .modal-body.pad-top {
    padding: 30px;
  }
  .step-banner-block .step-banner-imgblock,
  .paymentsuccessfull-wapper .step-banner-imgblock {
    display: none;
  }
  .crypto-connected-account .connected-account > li > span,
  .crypto-connected-account .connected-account > li > p {
    font-size: 12px;
  }
  .crypto-connected-account .connected-account > li > p {
    word-break: break-all;
  }

  .payment-success-banner {
    padding: 50px 0px;
  }

  .payment-success-banner .right-content {
    padding: 0;
    text-align: center;
    margin-top: 20px;
  }

  .payment-success-banner .right-content .join-us-wrapper .social-item,
  .payment-success-banner .right-content .share-story-wrapper .social-item {
    width: 100px;
  }

  .payment-success-banner .right-content .top-content {
    padding: 0 12px;
  }

  .success-card-container .mobile-padding {
    padding: 0 25px;
  }

  .payment-success-wapper .caption-box {
    padding: 30px;
  }
  .payment-success-wapper .caption-box .share-purchase {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 576px) {
  :root {
    --header_height: 60px;
    --section_space: 30px;
  }
  .logo-bar img {
    max-width: 180px;
  }
  .header-caption .fill-btn {
    height: auto;
    padding: 0px;
    font-size: 14px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 20px;
  }
  .convert-leos-capton .text-caption .convert-leos-text,
  .convert-leos-capton .text-caption .convert-leos-text span {
    font-size: 24px;
  }
  .fill-btn {
    height: 40px;
    font-size: 14px;
    padding: 15px;
  }
  .buy-leos-form-block .input-group .input-group-text {
    font-size: 18px;
  }
  .buy-leos-form-block .form-control {
    font-size: 16px;
  }
  .token-information-box {
    padding: 30px 15px;
    border-radius: 20px 20px 0 0;
    -webkit-border-radius: 20px 20px 0 0;
    -moz-border-radius: 20px 20px 0 0;
    -ms-border-radius: 20px 20px 0 0;
    -o-border-radius: 20px 20px 0 0;
  }
  .token-information-box .information-listing li span,
  .token-information-box .information-listing li p {
    font-size: 14px;
  }
  .token-sale-box {
    padding: 30px 15px;
  }
  .shadow-box .title {
    font-size: 16px;
  }
  .tokensale-info-box {
    padding: 10px 15px;
  }
  .tokensale-info-box span {
    font-size: 15px;
  }
  .features-listing-box .item-box {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 20px 15px;
  }
  .footer-bot-caption p,
  .footer-bot-caption .link-text {
    font-size: 14px;
  }
  .step-banner-block .caption-box {
    gap: 20px;
  }
  .step-banner-block h2 {
    font-size: 20px;
    line-height: 30px;
  }
  .step-banner-block .listing-item-box li {
    font-size: 16px;
  }
  .label-radio-btn .form-check-label span {
    font-size: 14px;
  }
  .step-banner-block .caption-box h3 {
    font-size: 18px;
  }
  .step-banner-block .caption-box p {
    font-size: 14px;
  }
  .step-link-btn-bar .fill-btn {
    height: auto;
    padding: 10px 15px;
  }
  .step-link-btn-bar .fill-btn span {
    text-align: start;
    line-height: 20px;
  }
  .paymentsuccessfull-wapper .caption-box .body-box {
    padding: 20px 15px;
  }
  .paymentsuccessfull-wapper .caption-box .body-box .text-caption p {
    font-size: 14px;
  }
  .paymentsuccessfull-wapper .caption-box .body-box .text-caption span {
    font-size: 20px;
  }
  .stepform-progress #RFS-Label {
    display: none;
  }
  .subscribe-marketing-checkbox .form-check {
    padding: 10px !important;
  }
  .square-tick-check .form-check-label {
    font-size: 12px;
  }
  .country_list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .support-users .user img {
    width: 70px;
    height: 70px;
  }
  .support-links a,
  .need-help-bar ul li a,
  .text-need-help {
    font-size: 14px;
  }
  .need-help-bar {
    justify-content: center !important;
  }
  .btn-profile span {
    font-size: 14px;
  }
  .btn-profile-avatar {
    border-radius: 50%;
    padding: 0;
    width: 28px;
    height: 28px;
  }
  .btn-profile-avatar svg {
    width: 16px;
  }
}

@media (max-width: 400px) {
  .logo-bar img {
    max-width: 160px;
  }
}
